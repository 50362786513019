import React from "react";
import Header from "../Header";
import access from "../../assets/image/designer_icon/accessdenied.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

export default function AccessDenied(props) {
  return (
    <React.Fragment>
      <section className="backco_white" style={{ fontFamily: "Arial" }}>
        <Header />
        <div className="App">
          <div className="container-fluid align-left ">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
              <div className="col-lg-6 col-md-6 col-sm-6 mt-5rem">
                <div className="message-background center-screen">
                  {console.log(props.location.state)}
                  <div className="text-center bg-silver wide-bg p-50">
                    <span className="accessImg">
                      {/* <img
                        src={image || access}
                        alt="Logo"
                        width="auto"
                        height="55px"
                      /> */}

                      <img src={access} alt="Logo" width="auto" height="55px" />
                    </span>
                    <div className="general-height mt-20"></div>
                    <h3 className="accessH text-red">
                      {props.location.state?.title || "Access Denied"}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        gap: "10px",
                        marginBottom: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      <p
                        className="accessP text-black"
                        style={{
                          margin: "0",
                          fontSize: "18px",
                        }}
                      >
                        1) At least 5 years of work experience since
                        registration with PEC{" "}
                        {props.location.state.hasValidExperience ? (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ) : (
                          <CancelIcon style={{ color: "red" }} />
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        className="accessP text-black"
                        style={{
                          margin: "0",
                          fontSize: "18px",
                        }}
                      >
                        2) At least 10 Work Based Activity points since 10th
                        July 2010
                      </p>
                      {props.location.state.hasValidWorkBasedPoints ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : (
                        <CancelIcon style={{ color: "red" }} />
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        className="accessP text-black"
                        style={{
                          margin: "0",
                          fontSize: "18px",
                        }}
                      >
                        3) At least 7 points other than work based activities since 10th July 2010
                        
                      </p>
                      {props.location.state.hasValidCPDPoints ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : (
                        <CancelIcon style={{ color: "red" }} />
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        className="accessP text-black"
                        style={{
                          margin: "0",
                          fontSize: "18px",
                        }}
                      >
                        4) Engineer graduated on or after 15 Jan, 2008
                      </p>
                      {props.location.state.isValidGraduate ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : (
                        <CancelIcon style={{ color: "red" }} />
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        className="accessP text-black"
                        style={{
                          margin: "0",
                          fontSize: "18px",
                        }}
                      >
                        5) Engineer should not have Professional Engineer title
                      </p>
                      {props.location.state.isNotPE ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : (
                        <CancelIcon style={{ color: "red" }} />
                      )}
                    </div>

                    {props.location.state?.title ===
                      "You are not eligible to appear in EPE" && (
                      <div
                        style={{
                          paddingTop: "20px",
                        }}
                      >
                        <button
                          className="button-claim"
                          onClick={() => {
                            props.history.push("/cpd/claimcpd");
                          }}
                        >
                          Claim More CPD Points
                        </button>
                        <p className="fs-13 text-danger">
                          {/* You can apply again once you have acquired the
                          required number of CPD points and 5 years of
                          experience. */}
                          Note: You can apply for EPE after completing the above
                          requirements.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3"></div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
